import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import UserState from './ContextAPI/UserState';


ReactDOM.render(
  <BrowserRouter>
    <UserState>
    <App />
    </UserState>
  </BrowserRouter>,
  document.getElementById('root')
);
reportWebVitals();