import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import Logo from "../Assets/img/Logo/logo.png";
import icondashboard1 from "../Assets/img/admin-icons/icon-dashboard-1.png";
import icondashboard2 from "../Assets/img/admin-icons/icon-dashboard-2.png";
import iconcoin1 from "../Assets/img/admin-icons/icon-coins-1.png";
import iconcoin2 from "../Assets/img/admin-icons/icon-coins-2.png";
import icontransaction1 from "../Assets/img/admin-icons/icon-trasaction-1.png";
import icontransaction2 from "../Assets/img/admin-icons/icon-trasaction-2.png";
import iconprofile1 from "../Assets/img/admin-icons/icon-profile-1.png";
import iconprofile2 from "../Assets/img/admin-icons/icon-profile-2.png";
import iconuser1 from "../Assets/img/admin-icons/icon-user-1.png";
import iconuser2 from "../Assets/img/admin-icons/icon-user-2.png";
import iconlogout1 from "../Assets/img/admin-icons/icon-logout-1.png";
import iconlogout2 from "../Assets/img/admin-icons/icon-logout-2.png";
import iconeye1 from "../Assets/img/admin-icons/icon-eye.png";
import iconeye2 from "../Assets/img/admin-icons/icon-eye-1.png";
import avatar from "../Assets/img/logoavat.png";


function NavBarDash() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const storedResponseString = localStorage.getItem("response");
  const storedResponse = JSON.parse(storedResponseString);
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary dashboard-navbar">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>
          <div className="nav-btns">
            <NavDropdown
              id="basic-nav-dropdown"
              title={
                <>
                  Hello! {storedResponse?.Data?.email && storedResponse.Data.email.replace("super", "").split('@')[0]
}
<span>a</span>
            {/* {""}      <img src={avatar} alt="" /> */}
                </>
              }
            >
              <NavDropdown.Item>
                <b className="yellow-color">{storedResponse?.Data?.email} </b>
                <br></br>
                {/* <b>{storedResponse?.Data?.email}</b> */}
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.2">
                <div className="img">
                  <img src={iconuser1} alt="Icon User" />
                  <img src={iconuser2} alt="Icon User" />
                </div>{" "}
                My Profile
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item href="#action/3.3">
                <div className="img">
                  <img src={iconeye1} alt="Icon Chain" />
                  <img src={iconeye2} alt="Icon Chain" />
                </div>{" "}
                Activity
              </NavDropdown.Item> */}
              <NavDropdown.Item onClick={handleLogout}>
                <div className="img">
                  <img src={iconlogout1} alt="Icon Logount" />
                  <img src={iconlogout2} alt="Icon Logount" />
                </div>{" "}
                Logout
              </NavDropdown.Item>
            </NavDropdown>
            <Navbar.Toggle aria-controls="navbarScroll" />
          </div>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">
              {/* <Nav.Link>
            <div className="">
                     <img src={Logo} alt="Logo" />
              
                     </div>{" "}
             
              </Nav.Link> */}
              <div></div>
              <Nav.Link
                href="/Dashboard"
                className={`${
                  useLocation().pathname == "/Dashboard" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={icondashboard1} alt="Dashboard" />
                  <img src={icondashboard2} alt="Dashboard" />
                </div>{" "}
                Dashboard
              </Nav.Link>
              <Nav.Link
                href="/Transactions"
                className={`${
                  useLocation().pathname == "/Transactions" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={icontransaction1} alt="Icon transaction" />
                  <img src={icontransaction2} alt="Icon transaction" />
                </div>{" "}
                Transactions
              </Nav.Link>
              <Nav.Link
                href="/UserList"
                className={`${
                  useLocation().pathname == "/UserList" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={iconprofile1} alt="Icon Profile" />
                  <img src={iconprofile2} alt="Icon Profile" />
                </div>{" "}
                Users List
              </Nav.Link>
              <Nav.Link
                href="/IcoStage"
                className={`${
                  useLocation().pathname == "/IcoStage" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={iconcoin1} alt="Icon cart" />
                  <img src={iconcoin2} alt="Icon cart" />
                </div>{" "}
                ICO/STO Stage
              </Nav.Link>
              <Nav.Link
                href="/Promo"
                className={`${
                  useLocation().pathname == "/Promo" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={iconcoin1} alt="Icon cart" />
                  <img src={iconcoin2} alt="Icon cart" />
                </div>{" "}
                Promo
              </Nav.Link>
              {/* <Nav.Link
                href="/MyToken"
                className={`${
                  useLocation().pathname == "/MyToken" ? "active" : ""
                }`}
              >
                <div className="img">
                  <i className="fa fa-gear"></i>
                </div>{" "}
                Settings
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div></div>
    </>
  );
}
export default NavBarDash;
