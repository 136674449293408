import axios from 'axios';

// export  const Base_Url =  'http://192.168.1.24:9854/v1/'
// export  const Base_Url =  'http://localhost:9854/v1/'
// export  const Base_Url =  'https://algtapi.algotechtrade.com/'

export const Base_Url = 'https://api.algotechtrade.io/v1/'
export  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
export   const nameRegex = /^[a-zA-Z]{3,15}[a-zA-Z\s]*$/;
export const FilterButtonColor= '#007bff'
export const Colors = {
    LavenderBlue: '#C1B6FF',
    GREEN: '#A2FF76',
    BLUEColor: '#ebedef',
    GREY: '#364152',
  }
  // export function addCommas(number) {
  //   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }
  export function addCommas(number) {
    const numString = number.toString();
    const numDigits = numString.length;
  
    if (numDigits <= 3) {
      return numString;
    }
  
    const firstPart = numString.slice(0, numDigits % 3 || 3);
    const remainingParts = [];
  
    for (let i = numDigits % 3 || 3; i < numDigits; i += 3) {
      remainingParts.push(numString.slice(i, i + 3));
    }
  
    return `${firstPart}${remainingParts.length ? ',' : ''}${remainingParts.join(',')}`;
  }

  export function addCommas1(number) {
    const parts = number.toString().split('.');
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    const decimalPart = parts[1] ? `.${parts[1].slice(0, 4)}` : '';
  
    return `${integerPart}${decimalPart}`;
  }
  
  export const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };
  export const formatDate1 = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };
  
  
// 3000/

const storedResponseString = localStorage.getItem("JsonWebToken");
const setAuthorizationToken = () => {
  axios.defaults.headers.common["Authorization"] = `${storedResponseString}`;
};
export default setAuthorizationToken;
