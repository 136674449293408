import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Pagination = ({ totalItems, itemsPerPage, onPageChange ,currentPage1}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const statePage = location.state && location.state.page;
  const currentPage = currentPage1 || 1;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    onPageChange(page)

    navigate(`${location.pathname}`, { state: { page } });
  };

  const pageRange = () => {
    const range = [];
    const delta = 2;

    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(totalPages - 1, currentPage + delta);
      i++
    ) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift("...");
    }
    if (currentPage + delta < totalPages - 1) {
      range.push("...");
    }

    range.unshift(1);
    if (totalPages !== 1) {
      range.push(totalPages);
    }

    return range;
  };


  return (
    <div className="pagination_2">
      <button
        className="pg-btn"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        &laquo;
      </button>
      {pageRange().map((page, index) => (
        <h6 className="panginate" key={index}>
          <button
            onClick={() => handlePageChange(page)}
            disabled={totalPages === 0}
            className={`${page === currentPage ? "active2" : "mainbtn"} ${
              page === "..." ? "ellipsis" : ""
            } ${page === totalPages ? "last" : ""}`}
          >
            {page}
          </button>
        </h6>
      ))}
      <button
        className="pg-btn"
        disabled={currentPage === totalPages || totalPages === 0}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        &raquo;
      </button>
    </div>
  );
};

export default Pagination;
