import React from "react";
import gif from "../Assets/img/gif.gif";
import { Col } from "react-bootstrap";

const Loader = () => (
    <Col xl="12" lg="12" md="12">

  <div className="laoder">
    <img className="ld" src={gif} alt="" />
  </div>
  </Col>
);
export default Loader;

