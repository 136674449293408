import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Table,
  Button,
  Dropdown,
  Tabs,
  Tab,
  Form,
  Modal,
} from "react-bootstrap";
import icontransaction from "../Assets/img/admin-icons/icon-swap.png";
import iconwallet from "../Assets/img/admin-icons/icon-wallet.png";
import iconeye from "../Assets/img/admin-icons/icon-eye.png";
import iconi from "../Assets/img/admin-icons/icon-i.png";
import { Notifications } from "../Components/Toast";
import { ActiveStage, AllStages, AllTransationShow, AllTransationStatus, ApproveTransaction, GetAllUsersbyName, GetOffTransationToken, RejectTrasnsaction } from "../Api's";
import Pagination from "../Components/Pagination";
import { formatDate } from "../Constants";
import Loader from "./Loader";
import Select from 'react-select';
import { use } from "echarts/lib/echarts";
import { PulseLoader } from "react-spinners";
function Transactions() {
  const { tab } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionsstatus, setTransactionsstatus] = useState([]);
  const [userByName, setUserByName] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionStage, setSelectedOptionStage] = useState(null);

  const [numberOfTokens, setNumberOfTokens] = useState('');
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedFilter, setselectedFilter] = useState(
    searchParams.get("tab") || "Purchase"
  );
  const filterStatus = {
    Approved: "Approved",
    pending: "Pending",
    Expired: "Expired",
  };
  const filter = {
    Purchase: "Purchase",
    Bonus: "Bonus",
    Referral: "Referral",
    Approved: "Approved",
    Pending: "Pending",
    All: "All",
  };
  const itemsPerPage = 5;
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setNumberOfTokens('');
    setSelectedOption(null);
    setSelectedOptionStage(null);
  };
  const [activestage, setactivestage] = useState("");
  const [loader, setloader] = useState(false)
  const [allstages, setallstages] = useState([])
  useEffect(() => {
    AllStages(100, 1).then((data) => {
      setallstages(data?.data)
    }).catch(() => {
    })
  }, [])
  const SetAllTransaction = async (page, selectedFilter) => {
    try {
      setIsLoading({ transactions: true });

      let response;

      if (selectedFilter === "Purchase" || selectedFilter === "Referral" || selectedFilter === "All" || selectedFilter === "Bonus") {
        response = await AllTransationShow(itemsPerPage, page, selectedFilter, searchTerm);
      } else {
        const result = selectedFilter === "Pending" ? 1 :
          selectedFilter === "Approved" ? 2 : 1;
        response = await AllTransationStatus(itemsPerPage, page, result, searchTerm);


      }

      const allTransactions = response?.data || [];
      setTransactions(allTransactions);
      setTotalItems(response?.totalItems);

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("tab", selectedFilter);
      window.history.replaceState({}, "", `?${searchParams.toString()}`);

      setError(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setError(true);
    } finally {
      setIsLoading({ transactions: false });
    }
  };
  useEffect(() => {
    SetAllTransaction(1, selectedFilter);
    setCurrentPage(1);
  }, [selectedFilter, searchTerm]);

  const handlePageChange = (page) => {
    SetAllTransaction(page, selectedFilter);
    setCurrentPage(page);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", page);
    window.history.replaceState({}, "", `?${searchParams.toString()}`);
  };

  const handleTabSelect = (selectedTab) => {
    setselectedFilter(selectedTab);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("tab", selectedTab);
    window.history.replaceState({}, "", `?${searchParams.toString()}`);
    SetAllTransaction(1, selectedTab);
    setSearchTerm("")
  };

  const ApprovetransactionFun = (id) => {
    ApproveTransaction(id).then(() => {
      SetAllTransaction(currentPage, selectedFilter);
      Notifications('success', 'Transaction Approve successfully')


    }).catch(() => {

    })
  }

  const RejecttransactionFun = (id) => {
    RejectTrasnsaction(id).then(() => {
      SetAllTransaction(currentPage, selectedFilter);
      // SetAllTransactionStatus(currentPage,selectedFilterStatus)

      Notifications('success', 'Transaction Rejected successfully')


    }).catch(() => {

    })
  }
  useEffect(() => {
    GetAllUsersbyName()
      .then((data) => {
        const user = data || [];
        setUserByName(user?.data);

      })
      .catch(() => {
      });
  }, []);

  const handleTokenChange = (event) => {
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
    setNumberOfTokens(sanitizedValue);
  };

  const GetActiveStage = async () => {
    try {
      const Active = await ActiveStage();
      setactivestage(Active);

    } catch (error) {
      Notifications("error", "not found");
    }
  };
  useEffect(() => {
    GetActiveStage()
  }, [])
  const CreateoffToken = async () => {
    const selectedUserId = selectedOption?.value?.id;
    const SelectedStage = selectedOptionStage?.value?.id
    if (!numberOfTokens) {
      Notifications('error', 'Please fill in all required fields');
      return;
    }
    if (!selectedUserId) {
      Notifications('error', 'Please select user');
      return;
    }
    if (!SelectedStage) {
      Notifications('error', 'Please select stage');
      return;
    }
    setloader(true);
    try {
      const requestData = {
        account_id: selectedUserId,
        stage_id: SelectedStage,
        token_purchased: numberOfTokens

      };
      await GetOffTransationToken(requestData);
      setSelectedOption(null);
      setSelectedOptionStage(null);
      Notifications('success', 'Send Tokens');
      setNumberOfTokens("")
      handleClose()
      GetActiveStage()


    } catch (error) {
      Notifications('error', 'Token is not send');
      setloader(false);

    } finally {
      setloader(false);
    }
  };






  const navigate = useNavigate()
  return (
    <>
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="white-post">
                  <div className="flex-div-xs align-items-center">
                    <h4 className="color font-weight-bolder">
                      Bonuses Transactions
                    </h4>
                    <Button className="btnback mr-2" onClick={handleShow}>
                      <i className="fa fa-plus-circle"></i> Add Tokens
                    </Button>
                    <Modal show={show} centered onHide={() => setShow(false)} animation={false} aria-labelledby="contained-modal-title-vcenter" size="lg" className="admin-modal">

                      <Modal.Body>
                        <Button className="close-btn" onClick={() => setShow(false)}>
                          <i className="fa fa-close"></i>
                        </Button>
                        <>
                          <h6>
                            Manually Send Tokens
                            {/* <span>UD09525</span> */}
                          </h6>
                          <Form>


                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                              <Form.Label className='mt-2  label-black'>Number of Token</Form.Label>
                              <Form.Control type="text" placeholder="Enter a number" value={numberOfTokens}
                                onChange={handleTokenChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
                              <Form.Label className='mt-2 label-black'>Token Added To</Form.Label>
                              <div className="selected">
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                                    }),
                                    menu: (baseStyles) => ({
                                      ...baseStyles,
                                      backgroundColor: '#FFFFFF',
                                      color: 'black',
                                    }),
                                  }}
                                  options={userByName?.map((user) => ({
                                    value: { id: user?.id, name: user?.email }, // Update value structure as needed
                                    label: user?.email
                                  }))}
                                  value={selectedOption}
                                  onChange={(selected) => setSelectedOption(selected)}
                                  isSearchable
                                />
                              </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                              <Form.Label className='mt-2 label-black'>Token for Stage</Form.Label>
                              <div className="selected">
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                                    }),
                                    menu: (baseStyles) => ({
                                      ...baseStyles,
                                      backgroundColor: '#FFFFFF',
                                      color: 'black',
                                    }),
                                  }}
                                  options={allstages?.map((user) => ({
                                    value: { id: user?.id, name: user?.level }, 
                                    label: user?.level
                                  }))}
                                  value={selectedOptionStage}
                                  onChange={(a) => setSelectedOptionStage(a)}
                                  isSearchable
                                />
                              </div>
                              {/* <Form.Control type="text" value={activestage?.level} readOnly /> */}
                            </Form.Group>



                          </Form>

                        </>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        {/* <Button  className="btnback mr-2" variant="primary" onClick={handleClose}>
Add Token          </Button> */}
                        <Button disabled={loader} className="btnback mr-2" variant="primary" onClick={CreateoffToken}>
                          {loader ? <PulseLoader size={10} color="#fff" /> : 'Add Token'}
                        </Button>
                      </Modal.Footer>

                    </Modal>
                  </div>
                  <div className="spacer-30"></div>
                  <div className="input-trans-container">
                    <div className="input-wrap">
                      <span class="input-icon input-icon-left">
                        {" "}
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Search by Transaction ID"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="input-solid input-transparent"
                      />
                    </div>
                    <Tabs
                      onSelect={(e) => handleTabSelect(filter[e])}

                      defaultActiveKey={selectedFilter}
                      id="uncontrolled-tab-example"
                      className="mb-3"

                    >
                      <Tab eventKey="Purchase" title="Purchase">
                        <div className="table-container">
                          <div className="table-container-inner">
                            <Table className="transaction-table">
                              <thead>
                                <tr>
                                  <th>TRANX ID</th>
                                  <th>TOKENS</th>
                                  <th>AMOUNT</th>
                                  <th>BASE AMOUNT</th>
                                  <th>PAY FROM</th>
                                  <th>PAYMENT ID</th>
                                  <th className="text-right">TYPE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading?.transactions ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <Loader animation="border" />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {transactions?.length ? (
                                      transactions.map(
                                        (transaction, index) => (
                                          <tr key={index}>
                                            <td className="d-flex">
                                              <span className="clock-span">
                                              {transaction?.status ===
                                                  "Approved"  ? <i className="fa fa-check"></i> :
                                                  transaction?.status ===
                                                  "Rejected"  ? <i className="fa fa-times"></i> :
                                                  <i className="fa fa-clock-o"></i>
                                             
                                                }
                                              </span>
                                              <div>
                                                <p className="m-0 font-weight-bold">
                                                  {transaction?.transaction_number
                                                  }
                                                </p>
                                                <span>
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[0]
                                                  }
                                                  ,{""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[1]
                                                  }{" "}
                                                  {""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[2]
                                                  }
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                +{transaction?.token_purchased}
                                              </p>
                                              <span>
                                                {transaction.tokenType}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_dollars}
                                              </p>
                                              <span>
                                                {/* USDT{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_crypto}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td cl>
                                              <p className="m-0 font-weight-bold">
                                                Pay with{" "}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* {transaction?.payment_id}{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td >
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.payment_id !== null ? transaction?.payment_id : 0}
                                              </p>


                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-end">
                                                <div className="reg-btn trans small mr-2">
                                                  {transaction?.type}
                                                </div>
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                  >
                                                    <i className="fa fa-ellipsis-h "></i>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item>
                                                      <Button
                                                        // style={{backgroundColor:'transparent',color:'blue',border:"none"}}

                                                        onClick={() => {

                                                          navigate(`/TransactionDetails/${transaction.id}`, { state: { transactionObject: transaction } });
                                                        }}
                                                        className="view"

                                                      >
                                                        <i className="fa fa-eye"></i>{" "}
                                                        View Details
                                                      </Button>
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item onClick={() => { ApprovetransactionFun(transaction?.transaction_number) }}>
                                                      <i className="fa fa-check-square-o"></i>{" "}
                                                      Approve
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item onClick={() => { RejecttransactionFun(transaction?.transaction_number) }}>
                                                      <i className="fa fa-minus-square"></i>{" "}
                                                      Cancel
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No transactions available.
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Pending" title="Pending">
                        <div className="table-container">
                          <div className="table-container-inner">
                            <Table className="transaction-table">
                              <thead>
                                <tr>
                                  <th>TRANX ID</th>
                                  <th>TOKENS</th>
                                  <th>AMOUNT</th>
                                  <th>BASE AMOUNT</th>
                                  <th>PAY FROM</th>
                                  <th>PAYMENT ID</th>
                                  <th className="text-right">TYPE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading?.transactions ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <Loader animation="border" />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {transactions?.length ? (
                                      transactions.map(
                                        (transaction, index) => (
                                          <tr key={index}>
                                            <td className="d-flex">
                                              <span className="clock-span">
                                              {transaction?.status ===
                                                  "Approved"  ? <i className="fa fa-check"></i> :
                                                  transaction?.status ===
                                                  "Rejected"  ? <i className="fa fa-times"></i> :
                                                  <i className="fa fa-clock-o"></i>
                                             
                                                }
                                              </span>
                                              <div>
                                                <p className="m-0 font-weight-bold">
                                                  {transaction?.transaction_number
                                                  }
                                                </p>
                                                <span>
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[0]
                                                  }
                                                  ,{""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[1]
                                                  }{" "}
                                                  {""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[2]
                                                  }
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                +{transaction?.token_purchased}
                                              </p>
                                              <span>
                                                {transaction.tokenType}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_dollars}
                                              </p>
                                              <span>
                                                {/* USDT{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_crypto}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                Pay with{" "}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* {transaction?.payment_id}{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td >
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.payment_id !== null ? transaction?.payment_id : 0}
                                              </p>


                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-end">
                                                <div className="reg-btn trans small mr-2">
                                                  {transaction?.type}
                                                </div>
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                  >
                                                    <i className="fa fa-ellipsis-h "></i>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item >
                                                      <Button
                                                        // style={{backgroundColor:'transparent',color:'blue',border:"none"}}

                                                        onClick={() => {

                                                          navigate(`/TransactionDetails/${transaction.id}`, { state: { transactionObject: transaction } });
                                                        }}
                                                        className="view"

                                                      >
                                                        <i className="fa fa-eye"></i>{" "}
                                                        View Details
                                                      </Button>
                                                    </Dropdown.Item>
                                                    {
                                                      transaction?.type === "Purchase" &&
                                                      <Dropdown.Item href={void (0)} onClick={() => { ApprovetransactionFun(transaction?.transaction_number) }} >
                                                        <i className="fa fa-check-square-o"></i>{" "}
                                                        Approve
                                                      </Dropdown.Item>
                                                    }

                                                    <Dropdown.Item onClick={() => { RejecttransactionFun(transaction?.transaction_number) }}>
                                                      <i className="fa fa-minus-square"></i>{" "}
                                                      Cancel
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No transactions available.
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Approved" title="Approved" >

                        <div className="table-container">
                          <div className="table-container-inner">
                            <Table className="transaction-table">
                              <thead>
                                <tr>
                                  <th>TRANX ID</th>
                                  <th>TOKENS</th>
                                  <th>AMOUNT</th>
                                  <th>BASE AMOUNT</th>
                                  <th>PAY FROM</th>
                                  <th>PAYMENT ID</th>
                                  <th className="text-right">TYPE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading?.transactions ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <Loader animation="border" />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {transactions?.length ? (
                                      transactions.map(
                                        (transaction, index) => (
                                          <tr key={index}>
                                            <td className="d-flex">
                                              <span className="clock-span">
                                              {transaction?.status ===
                                                  "Approved"  ? <i className="fa fa-check"></i> :
                                                  transaction?.status ===
                                                  "Rejected"  ? <i className="fa fa-times"></i> :
                                                  <i className="fa fa-clock-o"></i>
                                             
                                                }
                                              </span>
                                              <div>
                                                <p className="m-0 font-weight-bold">
                                                  {transaction?.transaction_number}
                                                </p>
                                                <span>
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[0]
                                                  }
                                                  ,{""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[1]
                                                  }{" "}
                                                  {""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[2]
                                                  }
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                +{transaction?.token_purchased}
                                              </p>
                                              <span>
                                                {transaction.tokenType}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_dollars}
                                              </p>
                                              <span>
                                                {/* USDT{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_crypto}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                Pay with{" "}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* {transaction?.payment_id}{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td >
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.payment_id !== null ? transaction?.payment_id : 0}
                                              </p>


                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-end">
                                                <div className="reg-btn trans small mr-2">
                                                  {transaction?.type}
                                                </div>
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                  >
                                                    <i className="fa fa-ellipsis-h "></i>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item >
                                                      <Button
                                                        // style={{backgroundColor:'transparent',color:'blue',border:"none"}}

                                                        onClick={() => {

                                                          navigate(`/TransactionDetails/${transaction.id}`, { state: { transactionObject: transaction } });
                                                        }}
                                                        className="view"

                                                      >
                                                        <i className="fa fa-eye"></i>{" "}
                                                        View Details
                                                      </Button>
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item href="#/action-2">
                                                      <i className="fa fa-check-square-o"></i>{" "}
                                                      Approve
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item onClick={() => { RejecttransactionFun(transaction?.transaction_number) }}>
                                                      <i className="fa fa-minus-square"></i>{" "}
                                                      Cancel
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No transactions available.
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Tab>


                      <Tab eventKey="Bonus" title="Bonus">
                        <div className="table-container">
                          <div className="table-container-inner">
                            <Table className="transaction-table">
                              <thead>
                                <tr>
                                  <th>TRANX ID</th>
                                  <th>TOKENS</th>
                                  <th>AMOUNT</th>
                                  <th>BASE AMOUNT</th>
                                  <th>PAY FROM</th>
                                  <th>PAYMENT ID</th>
                                  <th className="text-right">TYPE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading?.transactions ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <Loader animation="border" />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {transactions?.length ? (
                                      transactions.map(
                                        (transaction, index) => (
                                          <tr key={index}>
                                            <td className="d-flex">
                                              <span className="clock-span">
                                              {transaction?.status ===
                                                  "Approved"  ? <i className="fa fa-check"></i> :
                                                  transaction?.status ===
                                                  "Rejected"  ? <i className="fa fa-times"></i> :
                                                  <i className="fa fa-clock-o"></i>
                                             
                                                }
                                              </span>
                                              <div>
                                                <p className="m-0 font-weight-bold">
                                                  {transaction?.transaction_number
                                                  }
                                                </p>
                                                <span>
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[0]
                                                  }
                                                  ,{""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[1]
                                                  }{" "}
                                                  {""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[2]
                                                  }
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                +{transaction?.token_purchased}
                                              </p>
                                              <span>
                                                {transaction.tokenType}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_dollars}
                                              </p>
                                              <span>
                                                {/* USDT{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_crypto}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                Pay with{" "}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* {transaction?.payment_id}{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td >
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.payment_id !== null ? transaction?.payment_id : 0}
                                              </p>


                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-end">
                                                <div className="reg-btn trans small mr-2">
                                                  {transaction?.type}
                                                </div>
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                  >
                                                    <i className="fa fa-ellipsis-h "></i>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item >
                                                      <Button
                                                        // style={{backgroundColor:'transparent',color:'blue',border:"none"}}

                                                        onClick={() => {

                                                          navigate(`/TransactionDetails/${transaction.id}`, { state: { transactionObject: transaction } });
                                                        }}
                                                        className="view"

                                                      >
                                                        <i className="fa fa-eye"></i>{" "}
                                                        View Details
                                                      </Button>
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item href="#/action-2">
                                                      <i className="fa fa-check-square-o"></i>{" "}
                                                      Approve
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item onClick={() => { RejecttransactionFun(transaction?.transaction_number) }}>
                                                      <i className="fa fa-minus-square"></i>{" "}
                                                      Cancel
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No transactions available.
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Referral" title="Referral">
                        <div className="table-container">
                          <div className="table-container-inner">
                            <Table className="transaction-table">
                              <thead>
                                <tr>
                                  <th>TRANX ID</th>
                                  <th>TOKENS</th>
                                  <th>AMOUNT</th>
                                  <th>BASE AMOUNT</th>
                                  <th>PAY FROM</th>
                                  <th>PAYMENT ID</th>
                                  <th className="text-right">TYPE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading?.transactions ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <Loader animation="border" />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {transactions?.length ? (
                                      transactions.map(
                                        (transaction, index) => (
                                          <tr key={index}>
                                            <td className="d-flex">
                                              <span className="clock-span">
                                              {transaction?.status ===
                                                  "Approved"  ? <i className="fa fa-check"></i> :
                                                  transaction?.status ===
                                                  "Rejected"  ? <i className="fa fa-times"></i> :
                                                  <i className="fa fa-clock-o"></i>
                                             
                                                }
                                              </span>
                                              <div>
                                                <p className="m-0 font-weight-bold">
                                                  {transaction?.transaction_number}
                                                </p>
                                                <span>
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[0]
                                                  }
                                                  ,{""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[1]
                                                  }{" "}
                                                  {""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[2]
                                                  }
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                +{transaction?.token_purchased}
                                              </p>
                                              <span>
                                                {transaction.tokenType}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_dollars}
                                              </p>
                                              <span>
                                                {/* USDT{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_crypto}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                Pay with{" "}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* {transaction?.payment_id}{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td >
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.payment_id !== null ? transaction?.payment_id : 0}
                                              </p>


                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-end">
                                                <div className="reg-btn trans small mr-2">
                                                  {transaction?.type}
                                                </div>
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                  >
                                                    <i className="fa fa-ellipsis-h "></i>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item >
                                                      <Button
                                                        // style={{backgroundColor:'transparent',color:'blue',border:"none"}}

                                                        onClick={() => {

                                                          navigate(`/TransactionDetails/${transaction.id}`, { state: { transactionObject: transaction } });
                                                        }}
                                                        className="view"

                                                      >
                                                        <i className="fa fa-eye"></i>{" "}
                                                        View Details
                                                      </Button>
                                                    </Dropdown.Item>

                                                    {/* <Dropdown.Item href="#/action-2">
                                                      <i className="fa fa-check-square-o"></i>{" "}
                                                      Approve
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item onClick={() => { RejecttransactionFun(transaction?.transaction_number) }}>
                                                      <i className="fa fa-minus-square"></i>{" "}
                                                      Cancel
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No transactions available.
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="All" title="All">
                        <div className="table-container">
                          <div className="table-container-inner">
                            <Table className="transaction-table">
                              <thead>
                                <tr>
                                  <th>TRANX ID</th>
                                  <th>TOKENS</th>
                                  <th>AMOUNT</th>
                                  <th>BASE AMOUNT</th>
                                  <th>PAY FROM</th>
                                  <th>PAYMENT ID</th>
                                  <th className="text-right">TYPE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading?.transactions ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <Loader animation="border" />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {transactions?.length ? (
                                      transactions.map(
                                        (transaction, index) => (
                                          <tr key={index}>
                                            <td className="d-flex">
                                              <span className="clock-span">
                                                {transaction?.status ===
                                                  "Approved"  ? <i className="fa fa-check"></i> :
                                                  transaction?.status ===
                                                  "Rejected"  ? <i className="fa fa-times"></i> :
                                                  <i className="fa fa-clock-o"></i>
                                             
                                                }
                                              </span>
                                              <div>
                                                <p className="m-0 font-weight-bold">
                                                  {transaction?.transaction_number}
                                                </p>
                                                <span>
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[0]
                                                  }
                                                  ,{""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[1]
                                                  }{" "}
                                                  {""}
                                                  {
                                                    formatDate(
                                                      transaction?.created_at
                                                    ).split(", ")[2]
                                                  }
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                +{transaction?.token_purchased}
                                              </p>
                                              <span>
                                                {transaction.tokenType}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_dollars}
                                              </p>
                                              <span>
                                                {/* USDT{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.amount_in_crypto}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td>
                                              <p className="m-0 font-weight-bold">
                                                Pay with{" "}
                                              </p>
                                              <span>
                                                {transaction?.selected_crypto?.toUpperCase()}{" "}
                                                {/* {transaction?.payment_id}{" "} */}
                                                {/* <i className="fa fa-info-circle"></i> */}
                                              </span>
                                            </td>
                                            <td >
                                              <p className="m-0 font-weight-bold">
                                                {transaction?.payment_id !== null ? transaction?.payment_id : 0}
                                              </p>


                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-end">
                                                <div className="reg-btn trans small mr-2">
                                                  {transaction?.type}
                                                </div>
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                  >
                                                    <i className="fa fa-ellipsis-h "></i>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item >
                                                      <Button
                                                        // style={{backgroundColor:'transparent',color:'blue',border:"none"}}

                                                        onClick={() => {

                                                          navigate(`/TransactionDetails/${transaction.id}`, { state: { transactionObject: transaction } });
                                                        }}
                                                        className="view"

                                                      >
                                                        <i className="fa fa-eye"></i>{" "}
                                                        View Details
                                                      </Button>
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item href="#/action-2">
                                                      <i className="fa fa-check-square-o"></i>{" "}
                                                      Approve
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item onClick={() => { RejecttransactionFun(transaction?.transaction_number) }}>
                                                      <i className="fa fa-minus-square"></i>{" "}
                                                      Cancel
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No transactions available.
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>

                        <div className="spacer-20"></div>
                      </Tab>

                    </Tabs>
                  </div>
                  {transactions?.length > 0 && (
                    <div className="text-center">
                      <Pagination
                        currentPage1={currentPage}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPageChange={(page) => handlePageChange(page)}

                      />
                    </div>
                  )}


                  <div className="spacer-10"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
    </>
  );
}
export default Transactions;
